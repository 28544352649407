<div class="app-p4-mb8-mxauto-maxwscreenlg">

    <form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col mt-3 border rounded p-4 bg-white">

        <div class="grid grid-cols-12 gap-4">

            <mat-form-field class="col-span-6">
                <mat-label>Data</mat-label>
                <input matInput [matDatepicker]="date" formControlName="day">
                <mat-hint>gg/mm/aaaa</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-span-3">
                <mat-label>hh</mat-label>
                <mat-select formControlName="hh">
                    <mat-option *ngFor="let h of hours" [value]="h">
                        {{ h | number: '2.0-0' }}
                    </mat-option>
                </mat-select>                    
            </mat-form-field>

            <mat-form-field class="col-span-3">
                <mat-label>mm</mat-label>
                <mat-select formControlName="mm">
                    <mat-option *ngFor="let m of minutes" [value]="m">
                        {{ m | number: '2.0-0' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field class="col-span-4">
                <mat-label>nota nr.</mat-label>
                <input type="number" matInput formControlName="nr" class="text-right">
                @if(formData.controls.nr.errors?.['required']) {
                    <mat-error>Inserire numero</mat-error>
                }
                @if(formData.controls.nr.errors?.['min']) {
                    <mat-error>nr &gt; 0</mat-error>
                }
                @if(formData.controls.nr.errors?.['max']) {
                    <mat-error>nr &lt; 99999</mat-error>
                }
            </mat-form-field>

            <mat-form-field class="col-span-4">
                <mat-label>variaz.nr.</mat-label>
                <input type="number" matInput formControlName="sub_nr" class="text-right">
                @if(formData.controls.nr.errors?.['min']) {
                    <mat-error>nr &gt; 0</mat-error>
                }
                @if(formData.controls.nr.errors?.['max']) {
                    <mat-error>nr &lt; 99999</mat-error>
                }
            </mat-form-field>

            <lib-loading-button
                class="col-span-4 justify-self-end self-center"
                [loading]="saving()"
                [disabled]="formData.invalid || formData.pristine || saving()" 
                (onClick)="save()">
                SALVA
            </lib-loading-button>

        </div>

    </form>
    

    
    <section class="grid grid-cols-3 gap-1 my-3 border rounded p-4 bg-white">
        
        @for (toggleCtrl of formShipsArrayCtrl.controls; track $index; let idx = $index) {

            <mat-slide-toggle [formControl]="toggleCtrl" (toggleChange)="toggleShip(idx)">
                @if(crewNoteShipsLoading()[idx]) {
                    <mat-spinner diameter="16"></mat-spinner>
                } @else {
                    {{ eng_data()!.ships[idx].short_name }}
                }
            </mat-slide-toggle>

        }
        
    </section>


    <section>
        @for(crew_note_ship of crew_note().crew_note_ships; track crew_note_ship.id) {
            <div class="border rounded p-4 bg-white mb-3 flex flex-col">
                <div class="flex flex-row item-center">
                    <div class="flex-grow font-bold">
                        {{ crew_note_ship.ship!.name }}
                    </div>
                    <div class="flex-none">
                        <button mat-icon-button [routerLink]="[crew_note_ship.id]">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </div>
                @for(cns_sea_employee of crew_note_ship.crew_note_see_employees; track cns_sea_employee.id) {
                    <div class="grid grid-cols-3 gap-1">
                        <div>
                            {{ cns_sea_employee.board_qualification!.name }}
                        </div>
                        <div class="col-span-2">
                            {{ cns_sea_employee.sea_employee!.user_info!.name }} 
                            {{ cns_sea_employee.sea_employee!.user_info!.surname }}
                        </div>
                    </div>
                }
            </div>
        }
    </section>
   

</div>