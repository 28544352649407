import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	Signal,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import {
	ConfirmDialogComponent,
	HttpCrudService,
	InputRefTrimValueDirective,
	LoadingButtonComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { BaseCrudEntity } from '@webapp-nx-repo/lib-shared-shell';
import { map, tap } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
	EMPTY_CREW_NOTE,
	ICrewNote,
	ICrewNoteShip,
} from '../../models/crew-note';
import { DateTime } from 'luxon';
import { HttpCrewNoteService } from '../../services/http-crew-note.service';
import { NotaEquipaggioResolverT } from './nota-equipaggio-resolver';
import { IShip } from '../../models/ship';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'lib-nota-equipaggio',
	imports: [
		CommonModule,
        RouterLink,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatLuxonDateModule,
		LoadingButtonComponent,
	],
	templateUrl: './nota-equipaggio.component.html',
	styleUrl: './nota-equipaggio.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotaEquipaggioComponent extends BaseCrudEntity<ICrewNote> {
	private readonly httpCrewNoteService = inject(HttpCrewNoteService);
	private readonly fb = inject(FormBuilder);
	hours: number[] = [];
	minutes: number[] = [];

	formData = this.fb.group({
		nr: this.fb.control<number | null>(null, [
			Validators.required,
			Validators.min(1),
			Validators.max(99999),
		]),
		sub_nr: this.fb.control<number | null>(null, [
			Validators.min(1),
			Validators.max(99999),
		]),
		day: this.fb.nonNullable.control<DateTime>(DateTime.now(), [
			Validators.required,
		]),
		hh: this.fb.nonNullable.control<number>(8),
		mm: this.fb.nonNullable.control<number>(0),
	});

	formShipsArrayCtrl = this.fb.nonNullable.array<boolean>([]);
	crewNoteShipsLoading = signal<boolean[]>([]);

	crew_note = signal<ICrewNote>(EMPTY_CREW_NOTE);

	private data$ = this.route.data.pipe(
		map((data) => data['data'] as NotaEquipaggioResolverT),
		map(([crew_note, ships, board_qualifications]) => ({
			crew_note,
			ships: ships.filter((ship) => ship.short_name !== 'SF 2'),
			board_qualifications,
		})),
		tap((x) => {
			this.crew_note.set(x.crew_note);

			if (x.crew_note.id > 0) {
				this.formData.setValue({
					nr: x.crew_note.nr,
					sub_nr: x.crew_note.sub_nr,
					day: x.crew_note.dt,
					hh: Math.trunc(x.crew_note.dt.hour),
					mm: Math.trunc(x.crew_note.dt.minute / 5) * 5,
				});
			}

			this.crewNoteShipsLoading.set(x.ships.map(() => false));

			x.ships.forEach((ship) => {
				const ship_checked =
					x.crew_note.crew_note_ships?.some(
						(crew_note_ship) => crew_note_ship.ship_id === ship.id
					) || false;

				this.formShipsArrayCtrl.push(
					this.fb.nonNullable.control<boolean>(ship_checked)
				);
			});
		}),
		map((x) => ({
			ships: x.ships,
			board_qualifications: x.board_qualifications,
		}))
	);
	eng_data = toSignal(this.data$);

	disable_delete = computed<boolean>(
		() => this.saving() || this.crew_note().id === 0
	);

	constructor() {
		super();
		for (let i = 0; i < 24; i++) {
			this.hours.push(i);
		}
		for (let i = 0; i < 60; i = i + 5) {
			this.minutes.push(i);
		}
	}

	protected override buildHeader(): void {
		this.menuService.header.set({
			leftCmd: this.getBackLeftCommandHeader(),
			title: 'Nota equipaggio',
			rightCmd: [
				{
					icon: 'delete',
					label: 'elimina',
					fn: () => this.delete(),
					disabledFn: this.disable_delete,
				},
			],
		});
	}

	toggleShip(idx: number) {
		const ship = this.eng_data()!.ships[idx];
		const crew_note_ship = this.crew_note().crew_note_ships!.find(
			(x) => x.ship_id === ship.id
		);
		if (crew_note_ship) {
			this.deleteCrewNoteShip(idx, crew_note_ship.id);
		} else {
			this.insertShip(idx, ship);
		}
	}

	startLoading(idx: number) {
		this.crewNoteShipsLoading.update((x) => {
			x[idx] = true;
			return x;
		});
	}
	stopLoading(idx: number) {
		this.crewNoteShipsLoading.update((x) => {
			x[idx] = false;
			return [...x];
		});
	}

	/* #region SHIP */
	insertShip(idx: number, ship: IShip) {
		const newItem: ICrewNoteShip = {
			id: 0,
			crew_note_id: this.crew_note().id,
			ship_id: ship.id,
		};

		this.startLoading(idx);

		this.httpCrewNoteService.crew_note_ship
			.insert(newItem)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (srvItem: ICrewNoteShip) => {
					this.crew_note.update((x) => {
						x.crew_note_ships!.push({
							...srvItem,
							ship,
							crew_note_see_employees: [],
						});
						return x;
					});
					this.stopLoading(idx);
					this.onComplete('M/n inserita', false);
                    this.router.navigate([srvItem.id], { relativeTo: this.route });
				},
				error: (err: any) => {
					this.stopLoading(idx);
					this.onError(err, 'insert', "errore nell'inserimento");
				},
			});
	}

	deleteCrewNoteShip(idx: number, id: number) {
		const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
			data: {
				title: 'Elimina',
				messages: [`Vuoi cancellare la M/n e l\'equipaggio ?`],
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.startLoading(idx);
				this.httpCrewNoteService.crew_note_ship
					.delete(id)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe({
						next: () => {
							this.crew_note.update((x) => {
								x.crew_note_ships!.splice(
									x.crew_note_ships!.findIndex(
										(x) => x.id === id
									),
									1
								);
								return x;
							});

							this.stopLoading(idx);
							this.onComplete('M/n eliminata', false);
						},
						error: (err: any) => {
							this.stopLoading(idx);
							this.onError(
								err,
								'delete',
								'errore nella cancellazione'
							);
						},
					});
			}
		});
	}
	/* #endregion */

	// Implementation of: BaseCrudEntity<ICrewNote>
	//
	protected get_item(): ICrewNote {
		const item: ICrewNote = {
			id: this.crew_note().id,
			nr: this.formData.controls.nr.value!,
			sub_nr: this.formData.controls.sub_nr.value,
			dt: this.formData.controls.day.value.startOf('day').set({
				hour: this.formData.controls.hh.value,
				minute: this.formData.controls.mm.value,
			}),
			master_crew_note_id: null,
			mail_sent_at: null,
		};
		return item;
	}
	protected get_http_crud_service(): HttpCrudService<ICrewNote> {
		return this.httpCrewNoteService.crew_notes;
	}
	protected on_save_confirm_dialog_mgs(): string[] {
        let cn_label = `${this.formData.controls.nr.value}`;
        if(this.formData.controls.sub_nr.value) {
            cn_label += `.${this.formData.controls.sub_nr.value}`;
        }
		return [
			`Salvo la nota equipaggio nr.\"${cn_label}\" ?`,
		];
	}
	protected on_delete_confirm_dialog_mgs(): string[] {
		return [`Vuoi eliminare la nota equipaggio ?`];
	}

	protected override on_insert_complete_do_back(): boolean {
		return false;
	}
	protected override on_update_complete_do_back(): boolean {
		return false;
	}
	// back when delete complete

	protected override on_update_complete_fn(x: ICrewNote): void {
		// update form state to pristine and untouched
		this.formData.markAsPristine();
		this.formData.markAsUntouched();
	}

	protected on_insert_complete_msg(): string {
		return 'Nota equipaggio inserita';
	}
	protected on_update_complete_msg(): string {
		return 'Nota equipaggio aggiornata';
	}
	protected on_delete_complete_msg(): string {
		return 'Nota equipaggio eliminata';
	}
}
